import React from 'react'
import './Hero.css'
import Header from '../Headers/Header'
import Heart from '../../assets/heart.png'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Calories from '../../assets/calories.png'
// import {motion } from 'framer-motion'
const Hero = () => {
//  const transition = {type: 'spring', duration : 3}
  return (
    <>
    <div className='hero'>
      <div className='blur hero-blur'></div>
      <div className="left-h"><Header />
      {/* the best ad */} 
     <div className="the-best-ad">
        <div></div>
          {/* <motion.div
            initial={{left:'238px'}}
            whileInView={{left:'8px'}}
            transition={transition}
          /> */}
          <span>the best fitness club in the town</span>
      </div>
        {/* Hero Heading */}
        <div className="hero-text">
          <div>
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal body</span>
          </div>
          <div>
           <span>in here we will help you to shape and build your ideal body
            nd live up your life to fullest</span> 
          </div>
        </div>
        {/* figures */}
        <div className="figures">
          <div>
          <span>+140</span>
          <span>expert coachs</span>
          </div>
          <div>
          <span>+978</span>
          <span>members joined</span>
          </div>
          <div>
            <span>+50</span>
            <span>fitness programs</span>
              </div>
        </div>
        {/* hero button */}
        <div className="hero-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      
       <div className="right-h">
        <buttons className="btn">Join Now</buttons>
        <div className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </div>
        {/* hero img */}
<img src={hero_image} alt="" className='hero-image' />
<img src={hero_image_back} alt="" className='hero-image-back'/>
    {/* calories */}
    <div className="calories">
      <img src={Calories} alt="" />
      <div>
      <span>CaloriesBurned</span><span>220kcal</span>
      </div>
    </div>
       </div>
 
    </div>
    </>
  )
}

export default Hero
