import React from 'react'
import './Plans.css'
import { plansData } from '../../data/plansDate'
import whiteTick from '../../assets/whiteTick.png'

const Plans = () => {
  return (
   <div className="plans-container">
    <div className='blur plans-blur-1'></div>
    <div className='blur plans-blur-2'></div>
    <div className="programs-header">
        <span className='stroke-text'>Ready to start</span>
        <span> your journey</span>
        <span className='stroke-text'> now withus</span>
    </div>
    {/* plans cart */}
    <div className="plans">
      {plansData.map((plan, i)=>(
        <div className="plan" key={i}>
          {plan.icon}
          <span>{plan.name}</span>
          <span>${plan.price}</span>
        <div className="features">
            {plan.features.map((feature, i)=>(
              <div className="feature">
                <img src={whiteTick} alt="" />
                <span key={i}>{feature}</span>
        </div>
            )
            )}
          </div>
          <div>
            <span>See more benefits -> </span>
          </div>
          <button className="btn">join Now</button>
        </div>
      )
      )}
    </div>
   </div>
  )
}

export default Plans
